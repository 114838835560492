.card_section {
    display: flex;
    flex-direction: column;
    padding: 0 0 160px 0;
}

.card_section_title {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
}

@media (max-width: 500px){
    .card_section{
        display: block;
        padding: 100px 20px;
    }
    .card_section_title {
        font-size: 20px;
        font-weight: 600;
        margin: 100px auto;
        color: #122741;
    }
    
}
@media (min-width:501px) and (max-width:1024px){
    .card_section{
        display: block;
        padding: 100px 20px;
    }
    .card_section_title {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        margin: 20px auto;
        color: #122741;
    }
    
}