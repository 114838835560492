$b: 60px; // border-width
$blur: blur(9px);
$rect: inset(0);

.wrapper {
    width: 50%;
    min-height: 100%;
    overflow: hidden;
}

.container {
    height: 100%;
}

.border_blur {
    position: relative;
    border: solid $b rgba(196, 196, 196, 0.2);
    height: 100%;
    font-size: 2em;

    &:before {
        position: absolute;
        z-index: -1; /* put it *behind* parent */
        /* go outside padding-box by 
     * a border-width ($b) in every direction */
        top: -$b;
        right: -$b;
        bottom: -$b;
        left: -$b;
        border: inherit;
        border-color: transparent;
        background: inherit;
        background-clip: border-box;
        -webkit-filter: $blur;
        filter: $blur;
        -webkit-clip-path: $rect;
        clip-path: $rect;
        content: "";
    }
}

@media(max-width:500px){
    .border_blur{
        border: none;
        height: 568px;
        position: relative;
        top: 0px;
        filter: none;
    }
    .wrapper{
        width: 100%;
    }
    .container{
        height: auto;
    }
}
@media(min-width:501px) and (max-width:1024px){
    .border_blur{
        border: none;
        height: 1400px;
        position: relative;
        top: 0px;
        filter: none;
    }
    .wrapper{
        width: 100vw;
    }
    .container{
        height: auto;
    }
}

