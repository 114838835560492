// @import "../../styles/variables";

.card {
    width: 290px;
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.102);
    // box-shadow: $card-shadow;
}

.img_wrapper {
    // width: 100%;
    height: 210px;
    padding: 20px;
}

.image {
    width: 250px;
    height: 210px;
    border-radius: 16px;
    object-fit: cover;
}

.icon {
    display: block;
    width: 100%;
    font-size: 18px;
}

.title_wrapper {
    margin: 0 56px 48px 56px;
}

.title {
    color: #0d1c2d;
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    margin: 80px auto 32px auto;
}

.text {
    color: #0d1c2d;
    text-align: center;
    font-size: 18px;
}

.title_alt {
    color: #0d1c2d;
    text-align: center;
    font-weight: 300;
    font-size: 30px;
    margin: 64px auto 32px auto;
}

.text_alt {
    color: #0d1c2d;
    text-align: center;
    font-size: 16px;
    margin: auto 5px 64px 5px;
    font-weight: 400;
}

.salary {
    color: #0d1c2d;
    text-align: center;
    font-size: 26px;
    margin: 22px auto 32px auto;
}

.card_btn {
    height: 48px;
    display: flex;
    margin: 12px auto 68px auto;
}

.card_text {
    font-size: 18px;
    color: black;
    margin: auto 0;
    padding-left: 1.5rem;
    text-align: center;
    font-weight: 400;
}

.card_icon {
    width: 37px;
    margin: auto 0 auto 16px;
    height: unset;
}


@media (max-width:500px) {
    .card {
        // width: 75%;
        display: flex;
        flex-direction: column;
        border-radius: 32px;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.102);
        // box-shadow: $card-shadow;
        height: 100%;
        margin-bottom: 50px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .title_alt {
        color: #0d1c2d;
        text-align: center;
        font-weight: 300;
        font-size: 20px;
        margin: 45px auto 32px auto;
    }
    .text_alt {
        color: #0d1c2d;
        text-align: center;
        font-size: 14px;
        margin: 10px 20px;
        font-weight: 400;
        width: auto;
    }
    .title{
        font-size: 24px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .text{
        font-size: 12px;
    }
    .salary{
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .card_text{
        font-size: 11px;
    }
    .title_wrapper{
        margin: 0px 40px 10px 40px;
    }
}


@media (min-width:501px) and (max-width:1024px){
    .card {
        // width: 75%;
        display: flex;
        flex-direction: column;
        border-radius: 32px;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.102);
        // box-shadow: $card-shadow;

    }
    .title_alt {
        color: #0d1c2d;
        text-align: center;
        font-weight: 300;
        font-size: 20px;
        margin: 45px auto 32px auto;
    }
    .text_alt {
        color: #0d1c2d;
        text-align: center;
        font-size: 14px;
        margin: 10px 20px;
        font-weight: 400;
        width: auto;
    }
    
}