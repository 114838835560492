// @import "../../styles/variables";

.container {
    display: flex;
    flex-direction: row;
    // width: 80%;
    margin: auto;
}

.card_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 71px;
    /* margin: 5rem 0 6.4rem 0; */
    margin: 0 auto;
    width: 85.5%;
}

@media (min-width:1025px) and (max-width:1500px){

    .card_grid {
        display: flex;
        grid-template-columns: auto auto auto auto;
        grid-gap: 45px;
        /* margin: 5rem 0 6.4rem 0; */
        margin: 0 auto;
        width: 85.5%;
        margin-left: 40px;
    }
}
.card_bg {
    // background-color: $white-bg;
    background-color: white;
    padding: 11px;
}
 @media(max-width:500px){
    .card_grid{
        display: block;
        padding: 0px;
    }
 }
 @media(min-width:501px) and (max-width:1024px){
    .card_grid{
        display: grid;
        grid-template-columns: auto auto;
        //grid-row-start: index(1 );
    }
 }