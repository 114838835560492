.btn {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 1.3rem;
    text-decoration: none;
    color: black;
    margin: auto 0;
    cursor: pointer;
}

.icon {
    height: 2rem;
    width: auto;
    margin: 0 auto 0.8rem auto;
}
